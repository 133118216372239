import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ ]

  static values = { url: String, refreshInterval: Number }

  initialize () {
    this.intervalGetScannerLogs;
  }

  connect () {
    this.load()

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  disconnect () {
    this.stopRefreshing()
  }


  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  load () {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(json => {
        json.slice().reverse().forEach((log) => {
          let guid = log.guid
          let guidElement = document.getElementById(guid)

          if (!guidElement) {
            let guidElement = document.createElement("div")
            guidElement.id = guid
            guidElement.classList.add('list-group-item')
            if (log.status === 'scan_valid' && log.scan_state === 'scanned') {
              guidElement.classList.add('list-group-item-success')
            } else if (log.status === 'scan_valid' && log.scan_state === 'already_scanned') {
              guidElement.classList.add('list-group-item-info')
            } else if (log.status === 'scan_invalid') {
              guidElement.classList.add('list-group-item-warning')
            } else {
              guidElement.classList.add('list-group-item-danger')
            }

            let text = '<div class="d-flex w-100 justify-content-between"><h5 class="mb-1">' + log.message + '</h5><small>' + log.scanner_name + ' - ' + log.date + '</small></div>'
            if (log.order) {

              text += '<div class="d-flex w-100 justify-content-between align-items-end">' 
                text += '<div class="d-flex">'

                if (log.item.face_image) {
                  text += '<div class="mr-3">'
                    text += '<img src="' + log.item.face_image + '" loading="lazy" width="40"/>'
                  text += '</div>'
                }
                text += '<div>'
                  text += '<p class="mb-1">' + log.order.order_id + ': ' + log.order.order_name + '</p>'
                  text += '<p class="mb-1">' + log.ticket_type + ': ' + log.item.name
                  if (log.item.age) {
                    text += ' (' + log.item.age + ' jaar)'
                  }
                  text += '</p>'
                text += '</div>'
                text += '</div>'

                text += '<a href="' + log.order.link + '" target="_blank" class="pull-right ml-2 btn btn-primary btn-sm">' + 'Bestelling ' + log.order.order_id + '</a>'
              text += '</div>'
            } else {
              text += '<p class="mb-1">' + log.barcode + '</p>' 
            }

            guidElement.innerHTML = text
            this.element.insertBefore(guidElement, this.element.firstChild);
          }
        })

      }) 
  }

}

