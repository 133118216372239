import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "amount", "output", "radio" ]

  static values = { 
    custom: Boolean
  }

  connect() {
    if (!this.customValue) {
      this.amountTarget.closest('.form-group').style.display = 'none'
    }
  }

  amountTargetConnected(e) {
    this.formatAmount()
  }

  check(e) {

    this.radioTargets.forEach((element, index) => {
      element.closest('.btn').classList.remove('active')
    })
    e.target.closest('.btn').classList.add('active')

    const value = e.target.value

    if (value == 'custom') {
      this.amountTarget.closest('.form-group').style.display = ''
    } else {
      this.amountTarget.closest('.form-group').style.display = 'none'
      this.setOutput(value)
      this.setAmount(value)
    }

  }

  input(e) {
    const value = e.target.value
    this.setOutput(value)
    this.setAmount(value)
  }

  setAmount(value) {
    this.amountTarget.value = value //parseFloat(value).toFixed(2)
    this.formatAmount()
  }

  formatAmount() {
    console.log('formatAmount')
    this.amountTarget.value = parseFloat(this.amountTarget.value).toFixed(2)
  }

  setOutput(value) {
    const formated_value = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
    this.outputTarget.textContent = formated_value
  }
  
}

